/* Ensure the footer is at the bottom */
  
  /* Content takes up all available space and centers the inner content */
  #coming-soon-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 68vh;
  }
  
  /* Modern button style */
  #coming-soon-content a {
    margin-top: 20px;
    padding: 12px 24px;
    background-color: blue;
    color: white;
    text-decoration: none;
    border-radius: 0px;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect */
  #coming-soon-content a:hover {
    background-color: darkblue;
  }