  /* Content takes up all available space and centers the inner content */
  .waitlist-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 68vh;
      text-align: center;
      padding: 20px;
  }

  .waitlist-content p {
    font-size: 20px;
  }

    /* Style the link */
    .waitlist-content a {
      margin-top: 20px;
      padding: 12px 24px;
      background-color: blue;
      color: white;
      text-decoration: none;  
      transition: background-color 0.3s ease;
    }
    
    /* Hover effect */
    .waitlist-content a:hover {
      background-color: darkblue;
    }
