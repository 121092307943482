  /* Content takes up all available space and centers the inner content */
  .not-found-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 68vh;
    text-align: center;
    padding: 40px
  }
  
  /* Style the link */
  .not-found-content a {
    margin-top: 20px;
    padding: 12px 24px;
    background-color: blue;
    color: white;
    text-decoration: none;  
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect */
  .not-found-content a:hover {
    background-color: darkblue;
  }
  