/* Style for the table header */
.back-home {
  margin-left: 5%;
  margin-top: 4%;
}

.back-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.back-container:hover{
  text-decoration: none;
}

.back-container p {
  margin: 0;
  padding-left: 10px;
}

table thead {
  background-color: #050A30;
}

table thead th {
  text-align: center;
  color: white;
  padding: 15px; /* Optional, adds padding to header elements */
  font-weight: normal;
}

/* Style for the table body */
table tbody {
  background-color: #F7F7F7;
}

table tbody td {
  color: black;
  text-align: center;
  padding: 15px; /* Adds padding to body elements */
}

/* Style for the table borders */
table, th, td {
  border: 1px solid #D0D1D3;
}

/* To collapse borders */
table {
  border-collapse: collapse;
  margin: auto; /* Center the table horizontally */
  width: 90%; /* Make the table wider */
}

/* Center the whole container */
.constituents-container {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

h3 {
  margin-left: 5%;
}

@media (max-width: 1100px) {
  .hide-on-medium {
    display: none;
  }
}

@media (max-width: 834px) {
  .hide-on-small {
    display: none;
  }
}

@media (max-width: 700px) {
  .hide-on-extra-small {
    display: none;
  }
}