/* src/styles/index.css */

@font-face {
  font-family: 'Roboto';
  src: url('../../node_modules/typeface-roboto/files/roboto-latin-400.woff2') format('woff2'),
       url('../../node_modules/typeface-roboto/files/roboto-latin-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../node_modules/typeface-roboto/files/roboto-latin-700.woff2') format('woff2'),
       url('../../node_modules/typeface-roboto/files/roboto-latin-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html {
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: black;
}

.content-with-padding{
  padding-top: var(--header-height);
}

/* Chart section styles below */

.container {
  padding: 110px 3vw 110px 90px;
  display: flex;
  background-color: #050A30;  
  color: white;
}

.info-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.content {
  /* padding-left: 30px; */
  width: 33%;
}

.chart {
  width: 66%;
}

.overview {
  font-size: 16px;
  margin: 0 0 12px 0;
}

.horizontal-line {
  width: 40%;  /* adjusted width */
  border: 0;
  border-top: 1px solid #363F63;
  margin-top: 5px;  /* center the line */
  margin-left: 0px;
  margin-bottom: 0px;
}

.index-level,
.return {
  font-size: 16px;
  margin: 0;
}

.return-value,
.index-value {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 5px;
}

/* new classes to color the percent change */
.positive-percent {
  color: green;
}

.negative-percent {
  color: red;
}

.timeframe-buttons {
  display: flex;
  justify-content: flex-start; /* align items to the start */
  margin: 0 0 25px 0;
  gap: 15px; /* spacing between buttons */
}

.timeframe-button {
  border: none;
  padding: 10px 16px;
  background-color: #363F63;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  font-weight: bold;
}

.timeframe-button:hover {
  background-color: #fff;
  color: #363F63;
}

.timeframe-button.selected {
  background-color: #fff;
  color: #363F63;
}

@media screen and (max-width: 1300px) {
  .container {
    padding: 110px 40px 110px 60px;
    display: flex;
    background-color: #050A30;  
    color: white;
  }
}

@media screen and (max-width: 834px) {
  .container {
    flex-direction: column;
    padding: 50px 10px 50px 10px;
  }
  .content {
    width: 85%;
    margin-left: 20px;
  }
  .container hr {
    width: 100%;
  }

  .content h2 {
    font-size: 35px;
  }
  .chart {
    width: 100%;
  }

  .timeframe-button {
    padding: 8px 14px; /* reduced padding */
  }

  .timeframe-buttons {
    padding-left: 20px;
  }

  .info-container {
    flex-direction: row; /* stack them in a column when on mobile */
    margin-bottom: 30px;
  }

  .index-image {
    width: 80vw;
  }

  .index-name {
    height: 20vw;
  }
}

/* header styles below */

.header-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.85); /* Fallback background color */
  z-index: 1000; /* Updated z-index to the higher value */
  backdrop-filter: saturate(120%) blur(20px);
  transition: height 0.3s ease; /* Smooth transition for dynamic height changes */
}

@supports (backdrop-filter: saturate(120%) blur(5px)) {
  .header-container {
      background: white; /* Fully opaque white background if blur is supported */
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
}

.logo-container {
  padding: 1vw;
  padding-left: 7vw; 
}

.logo-container img {
  height: 60px;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 4vw;
  align-items: center;
}

nav ul li a {
  color: #757575; /* Making the text of the links gray (was black in your description) */
  font-weight: bold;
  transition: color 0.3s ease; /* Smooth color transition for hover */
}

nav ul li:last-child {
  padding-right: 8vw; /* Right padding */
}

nav ul li a:hover, .link-hover-effect:hover, .active-link {
  color: #000; /* Hover and active link color */
  text-decoration: none;
}

.active-link {
  color: black;
}

.link-hover-effect:hover {
  color: #000 !important;
}




/* ScientificApproach */

.mgc_box:hover {
  transform: scale(1.05); /* Scales up the box slightly */
  /* Removed box-shadow */
  transition: all 0.2s ease-in-out; /* Smooth transition for the effect */
}

/* Ensure this class is applied to your Text components to prevent grey background on hover */
.mgc_text:hover {
  background-color: transparent; /* This will prevent the grey background on hover */
}

/* MingCute icon styles */
.icon-black:before {
  font-size: 24px;
  color: black !important;
}

.icon-box:before {
  font-size: 32px;
  color: white !important;
}

.icon-cta::before{
  font-size: 24px; /* This will set the icon size */
  color: white !important;
  padding-left: 16px; /* This will add padding to the left of the icon */
}

.icon-external-link-blue::before {
  font-size: 24px;
  color: #3751FF !important;
}

.icon-external-link-darkblue::before {
  font-size: 24px;
  color: #050A30 !important;
}

.icon-ethereum-large::before {
  font-size: 24px;
  color: white !important;
}

/* Icons */
.menu-toggle {
  display: none; /* Initially hidden */
}

.header-container {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  /* Other styles */
}


/* Style for the special button */
.special-button {
  background-color: #233DFF;
  color: white;
  border-radius: 0; /* No rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  padding: 12px 22px;
}

/* Style for hover state */
.special-button:hover {
  background-color: darkblue; /* Slightly darker on hover */
  text-decoration: none;
}

@media screen and (max-width: 1150px) {
  nav ul {
    gap: 1.5vw;
  }
}

/* Mobile view */
@media (max-width: 950px) {

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer; /* Add pointer cursor for better UX */
    font-size: 24px;
    color: black;
    padding: 0;
  }

  .menu-open .menu-container {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    display: none;
    align-items: start;
  }

  .menu-open nav ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 0;
    font-size: 20px;
  }

  .menu-open .menu-toggle {
    position: absolute;
    top: 43px;
    right: 6vw;
  }

  header {
    padding: 20px 6vw 10px 0;
  }
}

/* IndexName section */

.index-name {
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5%;
}

.index-image {
  width: 35vw;
}

/* Mobile view */
@media (max-width: 834px) {

  .index-name {
    height: 20vw;
  }
  
  .index-image {
    width: 70vw;
  }
}

/* Information section */

#information-container {
  margin: auto;
  padding: 50px 100px;
  background-color: white;
}

#information-container h2 {
  margin: 0 0 20px 0;
  font-size: 30px;
  color: #050A30;
}

#information-container p {
  font-size: 16px;
  line-height: 1.6;
  color: #050A30;
}

#information-container hr {
  border: none;
  border-top: 1px solid #D0D1D3; /* Adjust the pixel value to make the line thinner */
  width: 50%; /* Adjust this to change how wide the line spans. */
  margin: 40px auto; /* 'auto' to center the line */
}

.content-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

/* For sections with split content (text and content side-by-side) */

.wide-section {
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  padding: 0px 2vw;
}

.wide-section div:first-child {
  flex-basis: 70%;
  max-width: 800px;
}

.wide-section div:last-child {
  flex-basis: 30%;
}

#document-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

#document-preview {
  min-width: 200px;
  max-width: 200px;
  height: 300px;
  background: white;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  position: relative;
  transition: transform 0.2s ease;  /* Add transition for smooth scaling */
}

#document-preview:hover{
  transform: scale(104%)
}

#document-preview img {
  width: 100%;
  filter: blur(2px);  /* Adjust the pixel value to control the amount of blur */
}

.centered {
  width: 50vw;
  height: 300px;
  margin: auto;
  text-align: center;
  justify-content: center;
}

#tokens-container {
  padding: 15px 20px 10px 20px;
  height: 250px;
  width: 270px;
  display: grid; /* Changed from flex to grid */
  grid-template-rows: repeat(3, 1.5fr) 1fr; /* Sets the size of the rows */
  align-items: center; /* Aligns items in the center of each row */
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
  color: black;
  align-items: center;
  transition: transform 0.2s ease;  /* Add transition for smooth scaling */
}

#tokens-container:hover {
  text-decoration: none;
  transform: scale(103%)
}

.token {
  display: flex;
  justify-content: space-between; /* Aligns groups to the left and right */
  align-items: center; /* Vertically centers the items */
  padding: 10px; /* Adds some space inside the div */
}

.token img {
  height: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.token .left-group {
  display: flex;
  align-items: center; /* Vertically centers the items */
}

.token .token-name {
  margin-left: 20px; /* Adds some space between the icon and the name */
}

.redirect-button-container {
  text-align: right; /* Aligns the button to the right */
}

.waitlist-container{
  display: flex;
  align-items: center;
}

.check {
  margin-right: 10px;
  font-size: 24px;
}

.email-input::placeholder {
  color: #D0D2D4;
}

.email-input {
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: bold;
  border: #050A30 1px solid;
  text-align: left;
  background-color: #F7F7F7;
  border: none;
  padding: 12px;
  color: #535353;
  border-radius: 0px;
}

.email-input.valid {
  background-color: rgba(0, 128, 0, 0.2); /* Green with 30% opacity */
}

.email-input.invalid {
  background-color: rgba(255, 0, 0, 0.2); /* Red with 30% opacity */
}

input:focus {
  outline: none;
  background-color: #e9e9e9;
}

#waitlist{
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 130px;
}

p#response-message{
  font-size: 18px;
}

@media screen and (max-width: 1100px) {

  .centered {
    width: 80vw;
  }

  #information-container h2 {
    font-size: 24px;
  }

  #information-container {
    padding: 50px 50px;
  }

}

@media screen and (max-width: 834px) {

  .email-input {
    width: 160px;
  }

  #waitlist{
    height: 42px;
    width: 130px;
  }

  .token {
    width: 250px;
  }

  #information-container {
    padding: 40px 5vw;
  }

  #information-container h2 {
    margin: 0 0 20px 0;
    font-size: 20px;
    color: #050A30;
  }

  .wide-section {
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }

  .centered{
    width: 100%;
  }

  #document-preview {
    min-width: 150px;
    max-width: 150px;
    height: 225px;
  }

  #hero-section {
    height: calc(155px + 0.2 * (834px - 100vw));
  }

  #hero-section p{
    margin: 0;
    padding: 0;
  }

  #preview-section {
    height: calc(370px + 0.2 * (834px - 100vw));
  }

  #tokens-section {
    height: calc(450px + 0.2 * (834px - 100vw));
  }

  #waitlist-section {
    height: calc(200px + 0.2 * (834px - 100vw));
  }
}

/* footer */

.footer-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; /* Aligns items to the start of the container */
  padding: 6vw; /* Adjusted padding to create space around items */
  background-color: #3751FF;
  color: white;
}

.footer-logo-and-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 10rem;
  padding-left: 2.17vw;
}

.footer-logo {
  width: 115px;
  margin-bottom: 1rem; /* Space below logo */
}

.icons-container {
  display: flex;
  flex-direction: row; /* Place icons side by side */
  align-items: center; /* Aligns icons to the center vertically */
}

.footer-icon-x {
  width: 20px;
  height: 20px;
  margin-right: 1rem; /* Space between icons */
}

.footer-icon-gitbook {
  width: 28px;
  height: 28px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5rem; /* Increased gap between the community and resources columns */
}

.community-section,
.resources-section {
  display: flex;
  flex-direction: column;
}

.footer-heading {
  font-weight: bold;
  margin-bottom: 0.5rem; /* Space below the headline */
}

.community-section a,
.resources-section a {
  color: white;
  text-decoration: none;
  margin-top: 0rem; /* Space above each link */
  margin-bottom: 0rem; /* Space below each link */
}

.community-section a:first-child,
.resources-section a:first-child {
  margin-top: 5; /* No space above the first link */
}

a:hover {
  color: #D0D2D4;
}

@media screen and (max-width: 834px) {
  .footer-container {
    flex-direction: column;
    align-items: flex-start; /* Aligns content to the left */
    padding: 5rem 3vw; /* Adjusted padding for mobile */
  }

  .footer-logo-and-icons {
    align-items: flex-start; /* Ensures the logo and icons are aligned to the left */
    padding-right: 0; /* Removes extra padding on the right */
    padding-bottom: 1rem;
    padding-left: 2rem;
  }

  .icons-container {
    flex-direction: row; /* Keeps icons side by side */
    justify-content: flex-start; /* Aligns icons to the start */
    gap: 0rem; /* Adjusts gap between icons */
    margin-bottom: 1.5rem; /* Space below icons before the columns start */
  }

  .footer-links {
    flex-direction: column; /* Stacks community and resources on top of each other */
    align-items: flex-start; /* Aligns the columns to the left */
    gap: 2.5rem; /* Space between community and resources sections */
    padding-left: 2rem;
  }

  .community-section,
  .resources-section {
    align-items: flex-start; /* Aligns the links inside the columns to the left */
    padding-left: 0; /* Resets any padding to align with the above elements */
  }

  .community-section a:first-child,
  .resources-section a:first-child {
    margin-top: 0; /* Resets the margin to 0 */
  }

  .community-section a,
  .resources-section a {
    margin-top: 0rem; /* Smaller space above each link */
    margin-bottom: 0.1em; /* Smaller space below each link */
  }
}